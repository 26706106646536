import { useState, useCallback, useEffect } from 'react'

const useResolution = () => {
  const [resolution, setResolution] = useState(global?.window?.innerWidth)

  const reportWindowSize = useCallback(() => {
    setResolution(global?.window?.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize)
    return () => global?.window?.removeEventListener('resize', reportWindowSize)
  }, [global?.window?.innerWidth])

  return resolution
}

export default useResolution
