const Footer = () => (
  <footer className="flex second-color items-center justify-center w-full h-[70px] shadow-lg">
    <div className="max-width h-full border-t-[1px] xl:w-web md:w-tablet w-mobile flex items-center justify-between">
      <p className="uppercase font-semibold text-black">Trustme</p>
      {/* <p className="text-center">
            Copyright
            {new Date().getFullYear()}
            , All Rights Reserved
          </p> */}
      <a
        href="https://www.facebook.com/%E1%83%97%E1%83%A0%E1%83%90%E1%83%A1%E1%83%97%E1%83%9B%E1%83%98-102192715045593/"
        target="_blank"
        title="Like us on Facebook"
        rel="noreferrer"
      >
        <i className="fa fa-facebook-square" />
      </a>
    </div>
  </footer>
)

export default Footer
