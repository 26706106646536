const navItems = [
  {
    name: 'მთავარი',
    containerId: 'main'
  },
  // {
  //   name: 'ჩვენ შესახებ'
  // },
  {
    name: 'ნამუშევრები',
    containerId: 'ourWork'
  },
  {
    name: 'კონტაქტი',
    containerId: 'contact'
  }
]

export { navItems }
