import { Link } from 'react-router-dom'

// styles
import './Card.scss'

type Props = {
  item: {
    id: number
    title: string
    image: any
    details: {
      id: number
      src: string
    }[]
  }
}

const Card = ({ item }: Props) => (
  <Link to={`/${item.id}`}>
    <div className="cardGrid z-10 bg-white transition-hover duration-300 md:hover:scale-[1.1] rounded-[12px] shadow-xl overflow-hidden w-full h-full">
      <div className="h-[64px] flex items-center justify-start p-4">
        <h2 className="text-[]">{item.title}</h2>
      </div>
      <div className="w-full imageContainer flex justify-center items-center overflow-hidden h-[200px]">
        <img className="" src={item.image} alt={`${item.title}-img`} />
      </div>
      {/* <div className="h-[64px] flex items-center justify-start p-4">
        <Link to={`/${item.id}`}>
          <button className="bg-[gray] text-white px-3 py-2 rounded-[12px] ">
            მეტის ნახვა
          </button>
        </Link>
      </div> */}
    </div>
  </Link>
)

export default Card
