// hooks
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'

// components
import App from './App'

// styles
import './App.scss'

// components

const rootElement: any = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </StrictMode>,
)
