// components
import Footer from './Footer'
import Header from './Header'

type Props = {
  children: JSX.Element[] | JSX.Element
}

function Layout({ children }: Props) {
  return (
    <>
      <Header />
      <div className="main">
        {children}
      </div>
      <Footer />
    </>
  )
}

export default Layout
