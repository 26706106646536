/* eslint-disable no-nested-ternary */
// assets
import { AddressIcon, MailIcon, TelephoneIcon } from '../../assets/icons'

const Contact = () => {
  const contactItems = [
    {
      title: 'მისამართი',
      content: 'ვიქტორ ნოზაძის ქუჩა N2, თბილისი',
      icon: <AddressIcon />,
    },
    {
      title: 'ტელეფონი',
      content: '598 44 90 03',
      icon: <TelephoneIcon />,
    },
    {
      title: 'ელ-ფოსტა',
      content: 'giorgi.kapanadze.1996@bk.ru',
      icon: <MailIcon />,
    },
  ]

  return (
    <section id="contact" className="flex second-color lg:py-[64px] py-8 items-center justify-center">
      <div className="max-width h-full text-[#475467] text-center xl:w-web md:w-tablet w-mobile flex flex-col items-center justify-center">
        <h2 className="lg:text-[44px] text-[22px] text-center lg:mb-12 mb-6">კონტაქტი</h2>
        <div className="flex w-full flex-col lg:flex-row lg:px-[117px] justify-between">
          {
            contactItems.map((item: any, index: number) => (
              <div key={index} className="flex mb-[24px] lg:mb-0">
                <div className="lg:w-[64px] lg:h-[64px] contactIcon w-[32px] h-[32px] bg-[#E4E7EC] rounded-full flex items-center justify-center">
                  {item.icon}
                </div>
                <div className="flex lg:ml-[21px] ml-2 justify-start lg:justify-center flex-col">
                  <h1 className="font-bold mb-2 text-lg text-left lg:text-[28px]">
                    {item.title}
                  </h1>
                  {index === 0 ? (
                    <a target="_blank" href="https://www.google.com/maps/place/1+%E1%83%95%E1%83%98%E1%83%A5%E1%83%A2%E1%83%9D%E1%83%A0+%E1%83%9C%E1%83%9D%E1%83%96%E1%83%90%E1%83%AB%E1%83%98%E1%83%A1+%E1%83%A5%E1%83%A3%E1%83%A9%E1%83%90,+T'bilisi/@41.7963164,44.7697778,17z/data=!3m1!4b1!4m5!3m4!1s0x40446e781c107c5b:0xfe25bec70f3f2f9d!8m2!3d41.7963124!4d44.7719665" rel="noreferrer">
                      <p className="text-base text-[#475467]">{item.content}</p>
                    </a>
                  ) : index === 1 ? (
                    <a
                      className="text-[#475467] text-base"
                      href={`tel:${item.content}`}
                    >
                      Call:
                      {item.content}
                    </a>
                  ) : (
                    <a
                      className="text-[#475467] text-base"
                      href={`mailto:${item.content}`}
                    >
                      {`Email: ${item.content}`}

                    </a>
                  )}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default Contact
