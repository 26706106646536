/* eslint-disable comma-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable global-require */
/* eslint-disable no-undef */
export const data = [
  {
    id: 1,
    title: 'მოაჯირი',
    image: require('../assets/images/moajiri/moajiri7.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/moajiri/moajiri1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/moajiri/moajiri2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/moajiri/moajiri3.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/moajiri/moajiri5.jpg')
      },
      {
        id: 6,
        src: require('../assets/images/moajiri/moajiri6.jpg')
      },
      {
        id: 7,
        src: require('../assets/images/moajiri/moajiri7.jpg')
      },
      {
        id: 8,
        src: require('../assets/images/moajiri/moajiri8.jpg')
      },
      // {
      //   id: 9,
      //   src: require("../assets/images/moajiri/moajiri9.jpg",)
      // },
      {
        id: 10,
        src: require('../assets/images/moajiri/moajiri10.jpg')
      },
      {
        id: 11,
        src: require('../assets/images/moajiri/moajiri11.jpg')
      },
      {
        id: 12,
        src: require('../assets/images/moajiri/moajiri12.jpg')
      },
      {
        id: 13,
        src: require('../assets/images/moajiri/moajiri13.jpg')
      },
      // {
      //   id: 14,
      //   src: require("../assets/images/moajiri/moajiri14.jpg"),
      // },
      {
        id: 15,
        src: require('../assets/images/moajiri/moajiri15.png')
      },
      {
        id: 16,
        src: require('../assets/images/moajiri/moajiri16.jpg')
      },
      // {
      //   id: 17,
      //   src: require("../assets/images/moajiri/moajiri17.jpg"),
      // },
      {
        id: 18,
        src: require('../assets/images/moajiri/moajiri18.jpg')
      }
      // {
      //   id: 19,
      //   src: require("../assets/images/moajiri/moajiri19.jpg"),
      // },
      // {
      //   id: 20,
      //   src: require("../assets/images/moajiri/moajiri20.jpg"),
      // },
    ]
  },
  {
    id: 2,
    title: 'გადახურვა',
    image: require('../assets/images/gadaxurva/gadaxurva11.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/gadaxurva/gadaxurva1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/gadaxurva/gadaxurva2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/gadaxurva/gadaxurva3.jpg')
      },
      {
        id: 4,
        src: require('../assets/images/gadaxurva/gadaxurva4.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/gadaxurva/gadaxurva5.jpg')
      },
      {
        id: 6,
        src: require('../assets/images/gadaxurva/gadaxurva6.jpg')
      },
      {
        id: 7,
        src: require('../assets/images/gadaxurva/gadaxurva7.jpg')
      },
      {
        id: 8,
        src: require('../assets/images/gadaxurva/gadaxurva8.jpg')
      },
      {
        id: 9,
        src: require('../assets/images/gadaxurva/gadaxurva9.jpg')
      },
      {
        id: 10,
        src: require('../assets/images/gadaxurva/gadaxurva10.jpg')
      },
      {
        id: 11,
        src: require('../assets/images/gadaxurva/gadaxurva11.jpg')
      }
    ]
  },
  {
    id: 3,
    title: 'გისოსი',
    image: require('../assets/images/gisosebi/gisosebi2.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/gisosebi/gisosebi1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/gisosebi/gisosebi2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/gisosebi/gisosebi3.jpg')
      },
      {
        id: 4,
        src: require('../assets/images/gisosebi/gisosebi4.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/gisosebi/gisosebi5.jpg')
      },
      {
        id: 6,
        src: require('../assets/images/gisosebi/gisosebi6.jpg')
      },
      {
        id: 7,
        src: require('../assets/images/gisosebi/gisosebi7.jpg')
      }
    ]
  },
  {
    id: 4,
    title: 'ეზოს კარი',
    image: require('../assets/images/ezoskari/ezoskari1.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/ezoskari/ezoskari1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/ezoskari/ezoskari2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/ezoskari/ezoskari3.jpg')
      },
      {
        id: 4,
        src: require('../assets/images/ezoskari/ezoskari4.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/ezoskari/ezoskari5.jpg')
      },
      {
        id: 6,
        src: require('../assets/images/ezoskari/ezoskari6.jpg')
      },
      {
        id: 7,
        src: require('../assets/images/ezoskari/ezoskari7.jpg')
      },
      {
        id: 8,
        src: require('../assets/images/ezoskari/ezoskari8.jpg')
      },
      {
        id: 9,
        src: require('../assets/images/ezoskari/ezoskari9.jpg')
      }
    ]
  },
  {
    id: 5,
    title: 'ეზოს მოაჯირი',
    image: require('../assets/images/ezosmoajiri/ezosmoajiri1.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/ezosmoajiri/ezosmoajiri1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/ezosmoajiri/ezosmoajiri2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/ezosmoajiri/ezosmoajiri3.jpg')
      },
      {
        id: 4,
        src: require('../assets/images/ezosmoajiri/ezosmoajiri4.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/ezosmoajiri/ezosmoajiri5.jpg')
      }
    ]
  },
  {
    id: 6,
    title: 'კარი',
    image: require('../assets/images/kari/kari1.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/kari/kari1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/kari/kari2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/kari/kari3.jpg')
      },
      {
        id: 4,
        src: require('../assets/images/kari/kari4.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/kari/kari5.jpg')
      },
      {
        id: 6,
        src: require('../assets/images/kari/kari6.jpg')
      },
      {
        id: 7,
        src: require('../assets/images/kari/kari7.jpg')
      },
      {
        id: 8,
        src: require('../assets/images/kari/kari8.jpg')
      },
      {
        id: 9,
        src: require('../assets/images/kari/kari9.jpg')
      },
      {
        id: 10,
        src: require('../assets/images/kari/kari10.jpg')
      },
      {
        id: 11,
        src: require('../assets/images/kari/kari11.jpg')
      },
      // {
      //     id: 12,
      //     src: require("assets/images/kari/kari12.jpg",)
      // },
      {
        id: 11,
        src: require('../assets/images/kari/kari13.png')
      }
    ]
  },
  {
    id: 7,
    title: 'კიბე',
    image: require('../assets/images/kibe/kibe10.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/kibe/kibe1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/kibe/kibe2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/kibe/kibe3.jpg')
      },
      {
        id: 4,
        src: require('../assets/images/kibe/kibe4.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/kibe/kibe5.jpg')
      },
      {
        id: 6,
        src: require('../assets/images/kibe/kibe6.jpg')
      },
      {
        id: 7,
        src: require('../assets/images/kibe/kibe7.jpg')
      },
      {
        id: 9,
        src: require('../assets/images/kibe/kibe9.jpg')
      },
      {
        id: 10,
        src: require('../assets/images/kibe/kibe10.jpg')
      }
    ]
  },
  {
    id: 8,
    title: 'კონტეინერი',
    image: require('../assets/images/konteineri/konteineri1.jpg'),
    details: [
      {
        id: 1,
        src: require('../assets/images/konteineri/konteineri1.jpg')
      },
      {
        id: 2,
        src: require('../assets/images/konteineri/konteineri2.jpg')
      },
      {
        id: 3,
        src: require('../assets/images/konteineri/konteineri3.jpg')
      },
      {
        id: 4,
        src: require('../assets/images/konteineri/konteineri4.jpg')
      },
      {
        id: 5,
        src: require('../assets/images/konteineri/konteineri5.jpg')
      }
    ]
  }
]
