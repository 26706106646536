/* eslint-disable no-unused-expressions */
// hooks
import { useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

// common
import { navItems } from '../common';

type Props = {
  isBurgerOpened: boolean;
  setIsBurgerOpened: (e: boolean) => void;
  setScrollTo: (e: string) => void;
  pathname: string
};

const BurgerMenu = ({
  isBurgerOpened,
  setIsBurgerOpened,
  setScrollTo,
  pathname
}: Props) => {
  const handleClose = () => setIsBurgerOpened(false);
  const navigate = useNavigate();

  return (
    <div
      className={`fixed bg-[#f0ece2] w-full top-0 transition-all h-screen duration-500 ease-in-out ${isBurgerOpened ? 'left-0' : 'left-full'
        }`}
    >
      <ul className="lg:hidden flex h-full flex-col w-full items-center justify-center">
        {
          navItems.map((item: any, index: number) => (
            <li
              key={index}
              className="border-b-[1px] mb-8 pb-2 border-transparent transition-all duration-300 lg:hover:border-[#596e79]"
            >
              <ScrollLink
                to={item.containerId}
                smooth
                offset={0}
                duration={800}
                spy
                onClick={() => {
                  pathname.length > 1 && navigate('/')
                  handleClose()
                }}
              >
                <p className="whitespace-nowrap font-medium cursor-pointer" onClick={() => setScrollTo(item.containerId)}>
                  {item.name}
                </p>
              </ScrollLink>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default BurgerMenu;
