const About = () => (
  <section>
    <div className="flex lg:pt-[64px] pt-8 items-center  justify-center">
      <div className="max-width h-full xl:w-web md:w-tablet w-mobile flex flex-col items-center justify-center">
        <p className="text-[#596e79] text-center lg:text-[24px] text-[18px]">
          ჩვენ გაგვაჩნია საჭირო რესურსი და შესაბამისი გამოცდილება
          <br />
          ხარისხიანი პროდუქციის დასამზადებლად
        </p>
        <span className="w-1/4 mt-8 border-[1px] border-[#dfd3c3]" />
      </div>
    </div>
  </section>
)

export default About;
