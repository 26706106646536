/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// hooks
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';

// context
import GlobalContext from '../GlobalContext';

// @ts-ignore
// assets
import Logo from '../../assets/images/logo.jpg'

// common
import { navItems } from '../../common';

// components
import BurgerMenu from '../BurgerMenu';

const Header = () => {
  const { scrollToElement, setScrollToElement }: any = useContext(GlobalContext);
  const [scroll, setScroll] = useState<boolean>(false);
  const location = useLocation();
  const [isBurgerOpened, setIsBurgerOpened] = useState<boolean>(false);
  const genericHamburgerLine: string = 'h-0.5 w-6 mb-1 rounded-full fourth-color transition ease transform duration-300';

  const { pathname } = location;
  const navigate = useNavigate();

  useEffect(() => {
    const checkScroll = () => {
      if (
        document.documentElement.scrollTop > 299
        || document.body.scrollTop > 299
      ) {
        setScroll(true);
      } else if (
        document.documentElement.scrollTop < 300
        || document.body.scrollTop < 300
      ) {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', checkScroll);

    return function cleanup() {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollToElement && pathname.length === 1) {
      scroller.scrollTo(scrollToElement, {
        duration: 1500,
        delay: 100,
        smooth: true,
      });
      setScrollToElement(null);
    }
  }, [scrollToElement, pathname]);

  const setScrollTo = (e: string) => {
    if (pathname.length > 1) {
      setScrollToElement(e);
    }
  };

  return (
    <>
      <header
        className={`fixed flex h-[60px] z-20 shadow-lg rounded-b-[24px] transition-all duration-300 items-center justify-center header
       ${!scroll ? 'lg:h-[110px]' : 'lg:h-[90px]'} w-full`}
      >
        <div className="absolute right-4 lg:hidden z-50">
          <button
            type="button"
            className="flex flex-col h-12 w-12 rounded justify-center items-center group"
            onClick={() => setIsBurgerOpened(!isBurgerOpened)}
          >
            <div
              className={`${genericHamburgerLine} ${isBurgerOpened ? 'rotate-45 translate-y-2' : ''
                }`}
            />
            <div
              className={`${genericHamburgerLine} ${isBurgerOpened ? 'opacity-0' : ''
                }`}
            />
            <div
              className={`${genericHamburgerLine} ${isBurgerOpened ? '-rotate-45 -translate-y-1' : ''
                }`}
            />
          </button>
        </div>
        <BurgerMenu
          isBurgerOpened={isBurgerOpened}
          setIsBurgerOpened={setIsBurgerOpened}
          setScrollTo={setScrollTo}
          pathname={pathname}
        />
        <div className="max-width h-full xl:w-web md:w-tablet w-mobile flex items-center justify-between">
          <div className="h-full flex items-center w-full">
            <ScrollLink
              to="main"
              smooth
              offset={0}
              duration={800}
              spy
              onClick={() => pathname.length > 1 && navigate('/')}
            >
              <div role="button" className="rounded-full shadow-2xl cursor-pointer z-10 lg:w-[64px] h-8 w-8 lg:h-[64px] overflow-hidden ">
                <img className="scale-[1.5] lg:pb-4 pb-1" src={Logo} alt="logo" />
              </div>
            </ScrollLink>
          </div>
          <ul className="lg:flex hidden flex-row items-center justify-center">
            {
              navItems.map((item: any, index: number) => (
                <li
                  key={index}
                  className="ml-12 border-b-[1px] pb-2 border-transparent transition-all duration-300 lg:hover:border-[#596e79]"
                >
                  <ScrollLink
                    to={item.containerId}
                    smooth
                    offset={0}
                    duration={800}
                    spy
                    onClick={() => pathname.length > 1 && navigate('/')}

                  >
                    <p className="whitespace-nowrap text-[#475467] font-medium cursor-pointer" onClick={() => setScrollTo(item.containerId)}>
                      {item.name}
                    </p>
                  </ScrollLink>
                </li>
              ))
            }
          </ul>
        </div>
      </header>
      <div
        id="main"
        className="h-[350px] bg-[center]"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1565621758117-7d6d460476f7?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80")',
        }}
      />
    </>
  );
}

export default Header;
