// hooks
import { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// facebook plugin
// @ts-ignore
import MessengerCustomerChat from 'react-messenger-customer-chat';

// pages
import Main from './components/Pages/Main';
import Details from './components/Pages/Details';
import GlobalContext from './components/GlobalContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/:id',
    element: <Details />,
  },
]);

function App() {
  const [scrollToElement, setScrollToElement] = useState<string | null>(null);
  return (
    <GlobalContext.Provider value={{ scrollToElement, setScrollToElement }}>
      <RouterProvider router={router} />
      <MessengerCustomerChat pageId="102192715045593" appId="936944293710155" />
    </GlobalContext.Provider>
  );
}

export default App;
