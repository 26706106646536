/* eslint-disable radix */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-array-index-key */
// hooks
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css';

// custom hooks
import useResolution from '../hooks/useResolution';

// types
import type { Item } from '../../types'
// components
import Layout from '../layout/Layout';

// api
import { data } from '../../api/data';

const Details = () => {
  const [currentData, setCurrentData] = useState<Item>();
  const location = useLocation();
  const resolution = useResolution();
  const pathname = location.pathname.substring(1);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const res: any = data.filter((item) => item.id === parseInt(pathname))[0];
    setCurrentData(res);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Trust Me |
          {` ${currentData?.title}` || ''}
        </title>
      </Helmet>
      <Layout>
        <section className="flex lg:py-[100px] py-[50px] flex-col justify-center items-center w-full">
          <div className="max-width h-full xl:w-web md:w-tablet w-mobile flex items-center justify-center">
            <h1 className="text-center lg:text-[32px] text-[#596e79] text-base lg:mb-12 mb-6">
              {`${currentData?.title?.slice(0, -1)}ი`}
              ს ნიმუშები
            </h1>
          </div>
          <div className="flex justify-center w-full">
            <Swiper
              freeMode
              grabCursor
              navigation={resolution > 375 && true}
              centeredSlides
              autoplay={{
                delay: 1500,
                disableOnInteraction: true,
              }}
              speed={800}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper w-full"
              breakpoints={{
                375: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {currentData
                && currentData.details?.map((item: any, index: number) => (
                  <SwiperSlide key={index}>
                    <div className="lg:w-[300px] mb-12 h-[300px] w-[90%]">
                      <img
                        className="object-contain w-full h-full"
                        src={item.src}
                        alt={`${currentData.title}-${index}`}
                      />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default Details;
