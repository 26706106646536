// hooks
import { Helmet } from 'react-helmet-async';

// api
import { data } from '../../api/data';

// components
import Layout from '../layout/Layout';
import Card from '../card/Card';
import About from '../mainPage/About';
import Contact from '../mainPage/Contact';

const Main = () => (
  <>
    <Helmet>
      <title>Trust Me</title>
    </Helmet>
    <Layout>
      <About />
      <section
        id="ourWork"
        className="flex justify-center lg:py-[128px] py-[48px] items-center"
      >
        <div className="max-width h-full xl:w-web md:w-tablet w-mobile flex flex-col items-center">
          <h1 className="lg:mb-[64px] mb-[32px] lg:text-[44px] text-[22px] text-[#596e79]">ნამუშევრები</h1>
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-1 sm:grid-cols-2 gap-[30px]">
            {data.map((item: any, index: number) => (
              <Card key={index} item={item} />
            ))}
          </div>
        </div>
      </section>
      <Contact />
      {/* <Map /> */}
    </Layout>
  </>
)

export default Main
